:root{
  
  
  --Normals-White: #FDF7FF;
  --Whites-Grayish:#D8CEE2;
  --Whites-Dark: #A9A3AD;
  --Darks-Dark: #231926;
  --Darks-LighterDark: #3F3B44;
  --Blueish:#2E809C;
  --Purple:#6322DE;
  --Gradient: linear-gradient(90deg, var(--Blueish) 0%, var(--Purple) 100%);
  --DarkGradient: linear-gradient(90deg, var(--Darks-LighterDark) 0%, var(--Darks-Dark) 100%);
}

*,*::before,*::after{
  margin:0;
  padding:0;
  box-sizing:border-box;
}

h1{
  font-family: "Krona One, Sans-serif";
  font-size: 81px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--Normals-White);
}

h3{
  font-family: Halant;
  font-size: 31px;
  font-style: normal;
  font: sans-serif;
  font-weight: 400;
  line-height: normal;
  color: var(--Normals-White);
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section {
  height:1wh;
  
}

.me{
  width:100%;
  height:50vh;
  background-image:url("./images/aboutMe.jpg");
  background-size:cover;
  background-position:center;
  background-attachment: fixed;
}