.loading {
    height:100vh;
    width:100%;
    background: var(--Gradient);   
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    gap:80px;
    top:0;
}

.WelcomeMessage{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    gap:30px;
}

.WelcomeMessage>*{
    margin:0;
}

.loading h3{
    font-size:2rem;
    color: var(--Normals-White);
}

.WelcomeMessage h1{
    font-family: Halant;
    font-size: 5rem;
    font-style: normal;
    font: sans-serif;
    font-weight: 400;
    line-height: normal;
    color: var(--Normals-White);
}

.anLogga h1{
    font-family:'Cinzel Decorative';
}

.anLogga{
    background-color: var(--Darks-Dark);
    border-radius: 50%;
    position:relative;
    height:100px;
    width:100px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation:rotate 4s linear infinite;
}


@keyframes rotate{
    0%{transform:rotate(0deg)}
    100%{transform:rotate(360deg)}
}